// Generated by Framer (83eb5d8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {ncYo1J1fe: {hover: true}};

const cycleOrder = ["ncYo1J1fe", "IlMnZSTQB"];

const serializationHash = "framer-pxyIl"

const variantClassNames = {IlMnZSTQB: "framer-v-1qdv3t", ncYo1J1fe: "framer-v-epvqkv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "ncYo1J1fe", "Variant 2": "IlMnZSTQB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ncYo1J1fe"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ncYo1J1fe", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-epvqkv", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"ncYo1J1fe"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"ncYo1J1fe-hover": {"data-framer-name": undefined}, IlMnZSTQB: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7QXJjaGl2by1yZWd1bGFy", "--framer-font-family": "\"Archivo\", \"Archivo Placeholder\", sans-serif", "--framer-font-size": "17px", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-1of0zx5, rgb(255, 255, 255))"}}>Explore All</motion.h2></React.Fragment>} className={"framer-iijw2"} fonts={["GF;Archivo-regular"]} layoutDependency={layoutDependency} layoutId={"xVPX1yKcj"} style={{"--extracted-1of0zx5": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", opacity: 0.5}} variants={{"ncYo1J1fe-hover": {opacity: 1}, IlMnZSTQB: {opacity: 1}}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-pxyIl[data-border=\"true\"]::after, .framer-pxyIl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pxyIl.framer-ty7n30, .framer-pxyIl .framer-ty7n30 { display: block; }", ".framer-pxyIl.framer-epvqkv { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 24px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-pxyIl .framer-iijw2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pxyIl.framer-epvqkv { gap: 0px; } .framer-pxyIl.framer-epvqkv > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-pxyIl.framer-epvqkv > :first-child { margin-left: 0px; } .framer-pxyIl.framer-epvqkv > :last-child { margin-right: 0px; } }", ".framer-pxyIl.framer-v-1qdv3t.framer-epvqkv { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 81
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"IlMnZSTQB":{"layout":["auto","fixed"]},"ENWPxgR8W":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerKvGFX7JPT: React.ComponentType<Props> = withCSS(Component, css, "framer-pxyIl") as typeof Component;
export default FramerKvGFX7JPT;

FramerKvGFX7JPT.displayName = "TemplatesExplore all";

FramerKvGFX7JPT.defaultProps = {height: 24, width: 81};

addPropertyControls(FramerKvGFX7JPT, {variant: {options: ["ncYo1J1fe", "IlMnZSTQB"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerKvGFX7JPT, [{family: "Archivo", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNp9R1oJ0vyVQ.woff2", weight: "400"}])